export default {
  gutter: {
    desktop: 120,
    mobile: 20,
  },
  header: {
    gutter: {
      desktop: 40,
      mobile: 20,
    },
  },
  maxWidth: 1200,
};
