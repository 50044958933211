/**
 * Defines helpful z-index values to use in css-in-js
 */
export default {
  modal: 200,
  modalBackground: 199,
  slideNavMenuHeader: 151,
  slideNavMenu: 150,
  slideNavMenuBackground: 149,
  stickyElement: 100,
};
